<template>
    <div class="px-3 py-2">
        <b-sidebar aria-labelledby="sidebar-no-header-title" width="700px" no-header id="add_vacation" backdrop style="direction:ltr" right title="اضافة مصروف دوري" shadow >
            <template #default="{ hide }">
                <div class="m-1 text-start" :style="`direction:rtl`">
                    <div style="width:150px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                        <span style="font-size:.8em;">{{$parent.lang.add_vacation}}</span>
                    </div>
                    <div @click="hide" id="hideAddVacations" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                        <span>{{$parent.lang.close}}</span>
                    </div>
                </div>
                <v-divider></v-divider>
                <div class="px-3 py-2" :style="`direction:`+$parent.lang.dir">
                    <v-form ref="apform">
                        <v-row>
                            <v-col cols="12" md="3" sm="12">
                                <label>{{$parent.lang.start_date}}</label>
                                <div class="flexTay">
                                    <b-form-select class="inborder text-center" style="width:30%;background:#efefef;" v-model="sd.day" :options="$store.state.daysList"/>
                                    <b-form-select class="inborder text-center" style="width:30%;background:#efefef;" v-model="sd.month" :options="$store.state.monthsList" />
                                    <b-form-select class="inborder text-center" style="width:60%;background:#efefef;" v-model="sd.year" :options="$store.state.yearList" />
                                </div>
                                <b-form-input class="inborder"
                                    :label="$parent.lang.start_date"
                                    type="date"
                                    style="display:none;"
                                    v-model="vacation.start_date"
                                    ></b-form-input>
                            </v-col>
                            <v-col cols="12" md="3" sm="12">
                                <label>{{$parent.lang.number_of_days}}</label>
                                <b-form-input class="inborder"
                                    :label="$parent.lang.number_of_days"
                                    v-model="vacation.number_of_days"
                                    >{{ reward_resone }}</b-form-input>
                            </v-col>
                            <v-col cols="12" md="6" sm="12">
                                <label>{{$parent.lang.vacation_reasons}}</label>
                                <b-form-input class="inborder"
                                    :label="$parent.lang.vacation_reasons"
                                    v-model="vacation.vacation_reasons"
                                    />
                            </v-col>
                            
                        </v-row>
                    </v-form>
                </div>
            </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" variant="success" @click="addit()" class="ma-2" style="width:100px;">{{$parent.lang.add}}</b-button>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify';
export default{
    data() {
        return {
            vacation: {
                start_date: '',
                number_of_days: '10',
                vacation_reasons: ''
            },
            empid: 0,
            amount: '',
            reward_resone: '',
            fieldRules: [
                v => !!v || this.$parent.lang.required_field
            ],
            sd: {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            }
        }
    },
    methods: {
        addit() {
            let vald = true;
            let message = '';
            this.vacation.start_date = `${this.sd.year}-${('0'+this.sd.month).slice(-2)}-${('0'+this.sd.day).slice(-2)}`;
            if(this.vacation.start_date == ''){
                vald = false;
                message = this.$parent.lang.vacation_start_date_required;
            }
            else if(this.vacation.vacation_reasons == ''){
                vald = false;
                message = this.$parent.lang.vacation_reasons_required;
            }
            if(!vald){
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","addVacations");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            if(this.vacation.number_of_days == '' || this.vacation.number_of_days == 0){
                this.vacation.number_of_days = 1;
            }
            post.append("data[empid]",this.empid);
            post.append("data[vacations]",JSON.stringify(this.vacation));
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                // // console.log(response.data);
                if(response.data.error.number == "201"){
                    this.$snotify.error(this.$parent.lang[response.data.error.message], 'تنبيه', {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.$parent.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    return false;
                }else{
                    this.vacation = {
                        start_date : '',
                        number_of_days: 10,
                        vacation_reasons: ''
                    }   
                    this.$parent.getEmployees();             
                    document.getElementById('hideAddVacations').click();
                }
                

            })
        }
    },
}
</script>
<style scoped>
.flexTay{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    justify-items: center;
}
.flexTay select{
    margin-inline-end: 2px;
}
</style>